import axios from "axios";

import ChatModel from "../../lib/ChatModel";
import io from "socket.io-client";
import CONSTANTS from "../../lib/Constants";

import moment from "moment";

export default {
  props: ["event", "eventMongo"],
  data() {
    return {
      moment,
      CONSTANTS,
      options: {},
      headers: [
        { text: "Pinned", value: "pinned", sortable: false, width: '140px' },
        { text: "Created At", value: "createdAt", sortable: false, width: '150px' },
        { text: "User", value: "user", sortable: false },
        { text: "Message", value: "message", sortable: false },
        { text: "Message Reply", value: "replyTo", sortable: false },
        { text: "Reply", value: "id", sortable: false, align: "center", width: '80px' },
        { text: "Action", value: "_id", sortable: false, align: "center", width: '80px' }
      ],

      eventMessages: [],
      loading: false,
      socket: null,
      valuePinned: false,
      pinned: false,

      userInfo: {
        id: localStorage.getItem('id'),
        user: localStorage.getItem('firstName'),
        email: localStorage.getItem('email'),
      },

      messageReply: {},
      dialogNewMessage: false,
      dialog: false,
      idMessageForm: '',
      messageForm: '',
      messageFormRules: [
        v => !!v || 'Message is required',
      ],
      checkboxForm: false,
      valid: true,
      validNewMessage: true,
      eventInfo: {},
      listReply: []
    };
  },

  computed: {
    totalMessages() {
      return this.eventMessages.length;
    },
  },

  methods: {

    /**
     * CHAT FUNCTIONALITY
     */
    getData() {

      let url_id = this.$route.params.event_id;
      const config = {
        headers: {
          'x-token-auth': localStorage.getItem('token')
        }
      };

      this.loading = true;

      const chat = axios.get(this.CONSTANTS.API_MONGO_BASE_URL + 'api/chat?event=' + url_id, config);
      const event = axios.get(this.CONSTANTS.API_MONGO_BASE_URL + 'api/events/' + url_id, config);

      return axios.all([chat, event])
        .then( (res) => {

          const [
            resChat,
            resEvent
          ] = res;

          const {
            data: dataChat
          } = resChat || {};

          const {
            data: dataEvent
          } = resEvent || {};

          this.eventInfo = dataEvent.data || {};

          const {
            items
          } = dataChat.data || {};

          this.eventMessages = [];
          this.eventMessages = items.map( (m) => {
            return {
              ...m,
              message: m.message.replace(/<\/?[^>]+(>|$)/g, "")
            }
          });

          items.forEach((e) => {
            if(e.replyTo){
              this.listReply.push(e.replyTo._id);
            }
          });

        })
        .finally( () => {
          this.loading = false;
        });

    },

    loadSocketIO() {

      this.socket = io(CONSTANTS.SOCKET_SERVER_URL, {
        secure: true,
        transports: ["websocket", "polling"],
      });

      this.socket.on("connect", () => {
        console.log("connected", this.eventInfo._id);
      });

      this.socket.emit("chat:signin", {
        type: "signin",
        event: `lancome-admin-${this.eventInfo._id}`,
        id: 1,
      });

      this.socket.on("chat:room", () => {
        console.log("Connected to ");
        console.log(`lancome-admin-${this.eventInfo._id}`);
      });

      // Reconnect
      this.socket.on("reconnect", () => {
        this.socket.emit("chat:signin", {
          type: "reconnect",
          event: `lancome-admin-${this.eventInfo._id}`,
          id: 1,
        });
      });

      this.socket.on('pin:updated', (data) => {

        console.log('pin:updated', data);

        const {
          _id,
          message
        } = data;

        const currentIndex = this.eventMessages.findIndex((m) => m._id === _id);

        if (currentIndex >= 0) {
          this.$set(this.eventMessages, currentIndex, { ...data, message: message.replace(/<\/?[^>]+(>|$)/g, "") });
        }

      });

      this.socket.on('chat:received', (data) => {

        this.eventMessages.unshift({
          ...data,
          message: data.message.replace(/<\/?[^>]+(>|$)/g, "")
        });

      });

    },

    cancelForm() {

      this.messageReply = {};
      this.idMessageForm = '';
      this.messageForm = '';
      this.dialog = false;
      this.dialogNewMessage = false;
      this.checkboxForm = false;

    },

    saveChat() {

      try {

        this.socket.emit('chat:message', {
          event: this.event._id,
          user: 'Moderator', // `Moderator | ${this.userInfo.user}`,
          userId: this.userInfo.id,
          email: this.userInfo.email,
          message: this.messageForm,
          pinned: this.checkboxForm,
        });

      } catch (e) {
        console.log(e, 'error');
      } finally {
        this.cancelForm();
      }

    },

    validateNewMessage() {
      if (this.$refs.formNewMessage.validate()) {
        this.saveChat();
      }
      return false;
    },

    dialogNew() {
      // console.log('ABRI EL DIALOG NEW MESSAGE');
      this.messageReply = {};
      this.dialogNewMessage = true;
    },

    deleteChatMessage(message) {


      if (!message) {
        return;
      }

      try {

        var r = confirm("This action can not be undone, are you sure you wan't to delete this message?");

        if (!r) {
          return;
        }

        this.loading = true;

        ChatModel.deleteEventChatMessages(message._id);

        this.socket.emit("chat:remove", {
          _id: message._id,
          event: this.eventInfo._id,
        });

        this.eventMessages = [...this.eventMessages.filter((m) => m._id !== message._id)];

      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }

    },

    filterHTMLTags(text) {
      return (text || '').replace(/<\/?[^>]+(>|$)/g, '');
    },

    /**
     * REPLY FUNCTCIONALITY
     */
    isReplyAllowed(item) {
      return this.listReply.includes(item);
    },

    dialogReply(item) {
      // console.log(item, 'ABRI EL DIALOG');
      this.messageReply = {};
      this.messageReply = { id: item._id, message: item.message }
      this.dialog = true;
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.saveChatReply();
      }
      return false;
    },

    saveChatReply() {

      try {

        this.socket.emit('chat:message', {
          event: this.event._id,
          user: 'Moderator', // `Moderator | ${this.userInfo.user}`,
          userId: this.userInfo.id,
          email: this.userInfo.email,
          message: this.messageForm,
          pinned: this.checkboxForm,
          replyTo: this.messageReply.id
        });

      } catch (e) {
        console.log(e, 'error');
      } finally {
        this.cancelForm();
      }

    },

    /**
     * PIN FUNCTIONALITY
     */
    pinnedMessage(data) {

      console.log('pinnedMessage', data);

      if ( data.pinned ) {
        this.socket.emit('pin:status', { event: this.event._id, message: data._id, status: 'show' });
      } else if ( !data.pinned ) {
        this.socket.emit('pin:status', { event: this.event._id, message: data._id, status: 'hide' });
      }

    },

    hidePinned(data) {
      if (data.pinned) {
        this.socket.emit('pin:status', { event: this.event._id, message: data._id, status: 'hide' });
      }
    },

    getEndTimeForPin(item) {

      const {
        pinnedAt
      } = item || {};

      return moment(pinnedAt || moment())
        .tz('America/New_York')
        .toDate()
        .getTime() + 121000;

    },

    /**
     * REPORT
     */
    exportChat() {

      let url_id = this.$route.params.event_id;

      axios
        .request({
          url: this.CONSTANTS.API_MONGO_BASE_URL + 'api/reports/chats?event=' + url_id,
          method: "GET",
          headers: { "x-token-auth": localStorage.getItem("token") },
          responseType: "blob",
        })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "Chats.xlsx"); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },

  async mounted() {

    await this.getData();

    this.loadSocketIO();

  }

};
